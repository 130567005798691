import React from 'react';
import PropTypes from 'prop-types';

import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import StartGreeting from '../components/StartGreeting';
import QuizResult from "../components/QuizResult";

const Home = ({id, go}) => {
    const handleStartQuiz = (e) => {
        go(e);
    };

    return (
        <Panel id={id} centered={true} separator={false}>
            <StartGreeting startQuiz={handleStartQuiz}/>
        </Panel>
    );
};

Home.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
    fetchedUser: PropTypes.shape({
        photo_200: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        city: PropTypes.shape({
            title: PropTypes.string,
        }),
    }),
};

export default Home;
