import React from 'react';
import PropTypes from 'prop-types';

import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Button from '@vkontakte/vkui/dist/components/Button/Button';

import fire from '../../img/icons/fire.png';
import './index.sass';

const StartGreeting = ( {startQuiz} ) => (
    <Div className={'greeting'}>
        <img src={fire} className={'greeting__icon'}/>
        <h1 className={'greeting__header'}>Поможем найти деньги<br/> за 30 секунд</h1>
        <h3 className={'greeting__description'}>
            Подберём новый микрозайм, потребительский кредит или кредитную карту
        </h3>

        <Button size="l" onClick={startQuiz} data-to={'quiz'} className={'greeting__button'}>
            Начать подбор
        </Button>
    </Div>
);

StartGreeting.propTypes = {
    startQuiz: PropTypes.func.isRequired,
};

export default StartGreeting;