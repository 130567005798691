import React from 'react'

import {Group, SimpleCell, InfoRow, Footer} from '@vkontakte/vkui';

export const CreditCardInfo = ({offer}) => {
    return (
        <Group>
            <SimpleCell multiline>
                <InfoRow header="Кредитный лимит">
                    <span>{ parseInt(offer.limits).toLocaleString() } руб.</span>
                </InfoRow>
            </SimpleCell>
            <SimpleCell>
                <InfoRow header="Процентная ставка">
                    <span>{ isNaN(offer.rate) ? offer.rate : offer.rate + '%' }</span>
                </InfoRow>
            </SimpleCell>
            <SimpleCell>
                <InfoRow header="Обсуживание">
                    <span>{ offer.service_pay }</span>
                </InfoRow>
            </SimpleCell>
            <SimpleCell>
                <InfoRow header="Беспроцентный период">
                    <span>{ offer.grace_period }</span>
                </InfoRow>
            </SimpleCell>
        </Group>
    );
};

export const CreditCardLicense = ({offer}) => {
  return (
      <Footer className={'offer__license'}>
          Банк «{ offer.bank_name }» (Лиц. №{ offer.bank_license })
      </Footer>
  );  
};