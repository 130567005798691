import {ADD_ITEM_TO_HISTORY, DELETE_LAST_ITEM_FROM_HISTORY} from "../actions";

export default (historyState = { history: ['home'], panel: 'home'}, action) => {
    const {type, payload} = action;

    switch (type) {
        case ADD_ITEM_TO_HISTORY: {
            const history = payload.history.slice();
            const panel = payload.panel;

            return [...history].concat(panel);
        }
        case DELETE_LAST_ITEM_FROM_HISTORY: {
            const history = payload.slice();
            return [...history.slice(0, history.length - 1)];
        }
    }

    return historyState;
}