import React from 'react'

import {Header, SimpleCell, InfoRow, Footer} from '@vkontakte/vkui';

export const MicroloanInfo = ({offer}) => {

    const getMethodsRetrieve = (offer) => {
        let methods = [];
        let result = '';

        if (offer.card === '1') { methods.push('на карту'); }
        if (offer.cash === '1') { methods.push('наличные'); }
        if (offer.transfer === '1') { methods.push('переводом'); }
        if (offer.e_money === '1') { methods.push('электронные деньги'); }

        methods.map((item, i) => {
            result += item + ', ';

            if (i > 0 && (i + 1) % 2 === 0) {
                result += "<br />";
            }
        });

        return result.slice(0, result.lastIndexOf(', '));
    };

    return (
        <div>
            <SimpleCell multiline>
                <InfoRow header="Сумма займа">
                    <span>{ parseInt(offer.max_loans).toLocaleString() } руб.</span>
                </InfoRow>
            </SimpleCell>
            <SimpleCell>
                <InfoRow header="Срок займа">
                    <span>{ offer.time_return }</span>
                </InfoRow>
            </SimpleCell>
            <SimpleCell>
                <InfoRow header="Первый займ под «0%»">
                    <span>{ (parseInt(offer.zero) === 1) ? 'да' : 'нет' }</span>
                </InfoRow>
            </SimpleCell>
            <SimpleCell>
                <InfoRow header="Способы получения">
                    <span dangerouslySetInnerHTML={{ __html: getMethodsRetrieve(offer) }} />
                </InfoRow>
            </SimpleCell>
        </div>
    );
};

export const MicroloanLicense = ({offer}) => {
    return (
        <Footer className={'offer__license'}>
            { offer.legacy_name } (Лиц. №{ offer.license })
        </Footer>
    );
};