import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Footer, Panel, Spinner} from '@vkontakte/vkui';

import QuizContent from '../components/QuizContent';
import QuizResult from '../components/QuizResult';

import {getQuizData} from '../services/DataProvider/QuizProvider';

const Quiz = ({id, go}) => {

    const [quizData, setQuizData] = useState({});
    const [loadingQuizData, setLoadingQuizData] = useState(true);
    const [currentScript, setCurrentScript] = useState(0);
    const [currentStep, setCurrentStep] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [lastQuestion, setLastQuestion] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const data = await getQuizData();
            setQuizData(data);
            setLoadingQuizData(false);
        }

        fetchData();
    }, []);

    const questionsCount = () => {
        return quizData.questions.filter((item) => {
            return item.script_id === currentScript;
        }).length;
    };

    const getQuestion = () => {
        if (currentScript === 0) {
            return quizData.questions[0];
        }

        return quizData.questions.filter((item) => {
            return item.script_id === currentScript;
        })[currentStep - 1];
    };

    const getOptions = (questionID) => {
        if (currentScript === 0) {
            return quizData.scripts;
        }
        return quizData.options.filter((item) => {
            return item.question_id === questionID;
        });
    };

    const computeQuiz = (option, isLast) => {
        const newUserAnswers = [...userAnswers, option];
        setUserAnswers(newUserAnswers);

        if (isLast) {
            setLastQuestion(true);

        } else {
            if (currentScript === 0) {
                let script = quizData.scripts.filter((item) => {
                    return (item.text.toLowerCase().trim() === option.toLowerCase().trim());
                });
                setCurrentScript(script[0].id);
            }
            setCurrentStep(currentStep + 1);
        }
    };

    const resultRender = () => {
        const question = getQuestion();
        const options = getOptions(question.id);

        return (lastQuestion) ? (
            <div>
                <QuizResult go={go} userAnswers={userAnswers}/>
            </div>
        ) : (
            <div>
                <QuizContent
                    id={id}
                    options={options}
                    question={question}
                    currentStep={currentStep}
                    questionsCount={questionsCount()}
                    computeOptionClick={computeQuiz}
                />
            </div>
        );
    };

    return (
        <Panel id={id} centered={true}>
            {loadingQuizData &&
                <div>
                    <Spinner size="regular" style={{marginTop: 20}}/>
                    <Footer>Загрузка вопросов...</Footer>
                </div>
            }

            {!loadingQuizData && resultRender()}

        </Panel>
    );
};

Quiz.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
    fetchedUser: PropTypes.shape({
        photo_200: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        city: PropTypes.shape({
            title: PropTypes.string,
        }),
    }),
};

export default Quiz;
