import {ACTIVE_PANEL} from "../actions";

export default (panel = 'home', action) => {
    const {type, payload} = action;

    switch (type) {
        case ACTIVE_PANEL:
            panel = payload['panel'];
            history.pushState(null, null, '#' + panel);
            return panel;
    }

    return panel;
}