import React, {useState} from 'react';

import {Button, Div, Footer, Radio, Title} from '@vkontakte/vkui';

import './index.sass';
import {ucFirst} from "../../lib/StringUtils";

const QuizContent = ({id, options, question, questionsCount, currentStep, computeOptionClick}) => {

    const [radioChanged, setRadioChanged] = useState(false);
    const [radioValue, setRadioValue] = useState(null);

    const handleOptionClick = () => {
        if (!radioValue) {
            return;
        }

        setRadioChanged(false);
        setRadioValue(null);

        computeOptionClick(radioValue, question.is_last);
    };

    const handleChange = (e) => {
        setRadioChanged(true);
        setRadioValue(e.target.value);
    };

    const prepareOptions = () => {
        return options.map((option) => {
            return (
                <Radio
                    name="radio"
                    value={option.text}
                    key={option.id + option.text}
                    onChange={handleChange}
                >
                    {ucFirst(option.text)}
                </Radio>
            );
        });
    };

    const btnClassName = () => {
        let style = 'options__next_btn';
        style += radioChanged ? ' options__next_btn--active' : '';
        return style;
    };

    return (
        <Div>
            <Div className={'options'}>
                <Title level={2} weight="bold" className={'options__title'}>{question.text}</Title>

                {prepareOptions()}

                <Button
                    size={'l'}
                    onClick={handleOptionClick}
                    mode={radioChanged ? 'primary' : 'secondary'}
                    className={btnClassName()}
                >
                    {((questionsCount - currentStep) === 0 && currentStep > 0) ? 'Подобрать' : 'Следующий вопрос'}
                </Button>
            </Div>


            <Footer>
                {questionsCount > 1 && 'Вопрос ' + currentStep + ' из ' + questionsCount}
            </Footer>
        </Div>
    );
};

export default QuizContent;