import React, {useEffect, useState} from 'react';
import bridge from '@vkontakte/vk-bridge';
import View from '@vkontakte/vkui/dist/components/View/View';

import {connect} from 'react-redux';
import {newPanel} from './redux/actionCreators/panelCreator';
import {addNewItemToHistory, deleteLastItemHistory} from './redux/actionCreators/historyCreator';

import '@vkontakte/vkui/dist/vkui.css';
import './App.sass';

import Home from './panels/Home';
import Quiz from './panels/Quiz';

const App = ({activePanel, setActivePanel, history, addToHistory, deleteLastItemHistory}) => {

    useEffect(() => {
        bridge.subscribe(({detail: {type, data}}) => {
            if (type === 'VKWebAppUpdateConfig') {
                const schemeAttribute = document.createAttribute('scheme');
                schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
                document.body.attributes.setNamedItem(schemeAttribute);
            }
        });

        window.addEventListener('popstate', () => go());

        // async function fetchData() {
        //     const user = await bridge.send('VKWebAppGetUserInfo');
        // }
        //
        // fetchData();

    }, []);

    const go = e => {
        const panel = (e) ? e.currentTarget.dataset.to : null;

        setActivePanel(panel || 'home');

        if (panel !== 'home' && panel !== null) {
            addToHistory({
                history: history,
                panel: panel
            });
        } else {
            if (history.length === 1) {
                bridge.send("VKWebAppClose", {"status": "success"});
            }
            deleteLastItemHistory(history);
        }
    };

    return (
        <View activePanel={activePanel}>
            <Home id='home' go={go} />
            <Quiz id='quiz' go={go}/>
        </View>
    );
};

const mapStateToProps = (state) => ({
    activePanel: state.activePanel,
    history: state.history,
});

const mapDispatchToProps = (dispatch) => ({
    setActivePanel: (panel) => dispatch(newPanel(panel)),
    addToHistory: (historyState) => dispatch(addNewItemToHistory(historyState)),
    deleteLastItemHistory: (history) => dispatch(deleteLastItemHistory(history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

