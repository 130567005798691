import React from 'react'

import {SimpleCell, InfoRow, Footer} from '@vkontakte/vkui';

export const CreditInfo = ({offer}) => {
    return (
        <div>
            <SimpleCell multiline>
                <InfoRow header="Сумма кредита">
                    <span>{ parseInt(offer.limits).toLocaleString() } руб.</span>
                </InfoRow>
            </SimpleCell>
            <SimpleCell>
                <InfoRow header="Срок кредита">
                    <span>до { offer.time_return } лет</span>
                </InfoRow>
            </SimpleCell>
            <SimpleCell>
                <InfoRow header="Процентная ставка">
                    <span>от { offer.min_percent + '%' }</span>
                </InfoRow>
            </SimpleCell>
        </div>
    );
};

export const CreditLicense = ({offer}) => {
    return (
        <Footer className={'offer__license'}>
            Банк { offer.legacy_name } (Лиц. №{ offer.license })
        </Footer>
    );
};