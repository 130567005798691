import React, {useEffect, useState} from 'react';
import {PanelHeader, PanelHeaderButton, Button, Card, Div, Footer, Group, Spinner, Title, Caption, platform, IOS} from '@vkontakte/vkui';

import {getOffersData} from '../../services/DataProvider/OfferProvider';

import { MicroloanInfo, MicroloanLicense } from './Offers/MicroloanInfo';
import { CreditCardInfo, CreditCardLicense} from '../QuizResult/Offers/CreditCard';
import { CreditInfo, CreditLicense } from './Offers/CreditInfo';

import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';

import './index.sass';

const QuizResult = ({userAnswers, go}) => {
    const [computingQuiz, setComputingQuiz] = useState(true);
    const [offers, setOffers] = useState([]);
    const [offerType, setOfferType] = useState('');
    const OS = platform();

    useEffect(() => {

        async function fetchData() {
            const data = await getOffersData(userAnswers);
            setOffers(data);
            setComputingQuiz(false);
        }

        fetchData();

        if (userAnswers[0] === 'микрокредиты') {
            setOfferType('MICRO_LOANS');
        }
        if (userAnswers[0] === 'кредитные карты') {
            setOfferType('CREDIT_CARDS');
        }
        if (userAnswers[0] === 'потребительский кредит') {
            setOfferType('CREDITS');
        }

        return (() => {
            setComputingQuiz(false);
        });

    }, []);

    const rendersOfferList = () => {
        if (offers.length === 0) {
            return <Footer>Извините, ничего не найдено.</Footer>;
        }

        return offers.map((item) => {

            const [header, description, logotypeFolder, license] = (() => {
                let result = [];

                switch (offerType) {
                    case 'MICRO_LOANS':
                        result = [
                            item.name,
                            <MicroloanInfo offer={item}/>,
                            'mfos/',
                            <MicroloanLicense offer={item} />
                        ];
                        break;
                    case 'CREDIT_CARDS':
                        result = [
                            item.name,
                            <CreditCardInfo offer={item}/>,
                            'credit_cards/',
                            <CreditCardLicense offer={item}/>
                        ];
                        break;
                    case 'CREDITS':
                        result = [
                            item.bank,
                            <CreditInfo offer={item}/>,
                            'banks/',
                            <CreditLicense offer={item}/>
                        ];
                        break;
                }

                return result;
            })();

            return (
                <Card size="s" mode="shadow" key={item.id + item.name} className={'offer'}>
                    <Group>
                        <Div>
                            <Title level="2" className={'offer__title'}>{header}</Title>
                        </Div>

                        <Div className={'offer__logotype'}>
                            <img src={ process.env.PUBLIC_URL + 'img/offers/' + logotypeFolder + item.logotype }/>
                        </Div>

                        {description}

                        {license}

                        <Div>
                            <a href={item.leads_link + '?source=q'} target={'_blank'} className={'offer__link'}>
                                <Button mode="commerce" size={'l'} stretched>Оформить онлайн</Button>
                            </a>
                        </Div>

                    </Group>
                </Card>
            );
        });
    };

    const panelButtonBack = () => (
        <PanelHeaderButton onClick={go} data-to="home">
            {OS === IOS ? <Icon28ChevronBack/> : <Icon24Back/>}
        </PanelHeaderButton>
    );

    return (
        <Div>
            <PanelHeader left={ panelButtonBack() } >Результат поиска</PanelHeader>
            {
                computingQuiz &&
                <div>
                    <Spinner size="regular" style={{marginTop: 20}}/>
                    <Footer>Идёт подбор...</Footer>
                </div>
            }

            {!computingQuiz &&
                <div>
                    <Caption level="3" weight="regular" style={{ marginBottom: 16 }}>
                        Предложения подобраны в порядке убывания популярности среди наших клиентов.
                    </Caption>

                    { rendersOfferList() }
                </div>
            }

        </Div>
    );
};

export default QuizResult;