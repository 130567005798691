import {ADD_ITEM_TO_HISTORY, DELETE_LAST_ITEM_FROM_HISTORY} from "../actions";

export const addNewItemToHistory = (historyState) => {
    return {
        type: ADD_ITEM_TO_HISTORY,
        payload: historyState
    }
};

export const deleteLastItemHistory = (history) => {
    return {
        type: DELETE_LAST_ITEM_FROM_HISTORY,
        payload: history
    }
};